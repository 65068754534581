import React, {useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import InputField from '../../../components/InputField';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';

const RegisterFamily = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');

    // Global
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { auth, setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [, setCookie] = useCookies({});

    // Form
    const { refCode, ...inputPropsCode } = register('code', {
        required: 'Code obligatoire',
    });

    // Call Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const result = await axiosPrivate.post('/guests/family/register', {
                code: data.code,
            }, {withCredentials: true});

            let child = result.data.children[0];
            setAuth({ ...auth, childId: child.id });
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, childId: child.id }, { path: '/' })
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {'id': child.id, 'name': child.firstName, 'point': child.jabuPoint}, { path: '/' })
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, true, {path: '/'})
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, null, {path: '/'})
            navigate('/confirm-family', { state: { codeFamily: data.code }});
        } catch (err) {
            let error;
            if (err?.response?.status === 404) {
                error = 'Pas d\'enfant trouvé avec ce code famille';
            } else {
                error = 'Erreur Serveur';
                navigate('/500');
            }

            setFlash({ message: error });
            setShowFlash(true);
        }

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        // Redirect if choice already made
        if (auth?.canteenId !== null || auth?.childId !== null) {
            navigate('/');
        }
    }, [])

    return (
        <>
            <div className='profil-page d-flex min-vh-100 justify-content-center'>
                <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                    <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                        <div className='d-flex profile-header-back'>
                            <Link to='/choice-family'>
                                <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                    <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                </span>
                            </Link>
                        </div>
                        <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Mon profil</div>
                    </div>

                    <div className='d-flex flex-column profile-block flex-grow-1'>
                        <div className='user-title d-flex justify-content-center'>
                            Renseigner mon code famille
                        </div>

                        <form className="d-flex flex-column flex-grow-1" onSubmit={handleSubmit(submit)}>
                            <div className="d-flex align-items-center justify-content-center flex-grow-1">
                                <InputField color="#000" label="Code Famille *" className="user-input" error={!!errors.code} helper={errors?.code?.message} inputRef={refCode} inputProps={inputPropsCode}></InputField>
                            </div>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <input className='user-submit' type='submit' value='Suivant'></input>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterFamily;
