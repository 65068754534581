import React, { useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

const UserModel = () => {
    // Global
    const { auth } = useAuth();
    const navigate = useNavigate();
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');

    useEffect(() => {
        // Redirect if choice already made
        if (auth?.canteenId !== null || auth?.childId !== null) {
            navigate('/');
        }
    }, [])

    return (
        <>
            <div className='profil-page d-flex min-vh-100 justify-content-center'>
                <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                    <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                        <div className='d-flex profile-header-back'>
                            &nbsp;
                        </div>
                        <div style={{margin:0}} className='d-flex justify-content-center flex-grow-1 profile-header-text'>Bonjour {auth?.firstName}</div>
                        <div><img className='logo-tiny' src='/images/logo-tiny.png' alt='Jabu'></img></div>
                    </div>

                    <div className='d-flex flex-column profile-block flex-grow-1'>
                        <div className='user-title d-flex justify-content-center'>
                            Bienvenue sur Jabu
                        </div>

                        <div className='user-subtitle d-flex justify-content-center' style={{'margin': '20px 0'}}>
                            Qui déjeune à la cantine ?
                        </div>

                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div style={{'margin-top': '1.5rem', 'margin-bottom': '5px'}}>
                                <Link className='user-child-button d-flex justify-content-center align-items-center' to='/register-canteen'>Moi</Link>
                            </div>
                            <div className='my-4'>
                                <Link className='user-child-button d-flex justify-content-center align-items-center' to='/choice-family'>Mes enfants</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserModel;
