import React, { useEffect, useState } from 'react';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import MenuFooter from '../../components/MenuFooter';
import { useCookies } from 'react-cookie';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const News = () => {
    const [unsolds, setUnsolds] = useState(true);
    const [cookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]);
    const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id']
    const canteenName = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['name']
    const axiosPrivate = useAxiosPrivate();
    const today = new Date()
    const currentDate = today.toJSON().slice(0, 10)

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');

    const getUnsolds = async () => {
        try {    
            const result = await axiosPrivate.get(`/guests/canteen/unsolds?canteenId=${canteenId}&requestedDate=${currentDate}`, { withCredentials: true })
            setUnsolds(result.data)
        } catch (error) {
            setUnsolds({remains:null})
        }
    }

    useEffect(() => {
        getUnsolds()
    }, [])

    return (
        <div className='home-page d-flex news min-vh-100 justify-content-center'>
            <div className='meal-header'>
                <ProfileHeader bg='points-header' canteenName={canteenName} page='news'></ProfileHeader>
            </div>

            <div id='news-infos'>
                <p className='px-4 text-center'><img className='unsold-image' src='/images/news.png' alt='Actualités'></img></p>
                <p className='px-4 text-center'>En préparation !</p>
                <p className='px-4 text-center'>Bientôt vous pourrez explorer le blog de votre cantine, et découvrir toutes les actions passionnantes menées en cuisine.</p>
                <p className='px-4 text-center'>Patience, les nouvelles croustillantes arrivent !</p>
            </div>

            <MenuFooter item='news' unsoldsRemain={unsolds.remains}></MenuFooter>
        </div>
    );
};

export default News;
