import React, { useEffect } from 'react';
import ProfileSection from '../../components/Profile/ProfileSection';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useCookies } from 'react-cookie';


const UserProfile = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');
    // Provider
    const { auth } = useAuth();
    const navigate = useNavigate();
    const [cookies] = useCookies();

    useEffect(() => {
        // Redirect if not choice
        if (auth?.canteenId === null && auth?.childId === null) {
            navigate('/register-model');
        }
    }, [])

    let userPoints;
    if ('undefined' !== cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]){
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['point'] ?? 0
    } else {
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint'] ?? 0
    }

    return (
        <div className='profil-page d-flex min-vh-100 justify-content-center'>
            <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                    <div className='d-flex profile-header-back'>
                        { 'true' !== cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`] &&
                            <Link to='/'>
                                <span style={{backgroundColor: '#F5F4ED', borderRadius: '20px', padding: '10px' }}>
                                    <i style={{fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                </span>
                            </Link>
                        }
                    </div>
                    <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Bonjour {auth?.firstName}</div>

                    <div className='header-qr-code d-flex justify-content-end align-items-center'>
                        <div className='header-points'>
                            <img style={{width: '40px'}} src='/images/points-icon.png' alt='points'></img>
                            <span className='avatar-name'>{userPoints}</span>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-column profile-block'>
                    <div className='d-flex flex-column'>
                        <div className='profile-section-title'>Mon profil</div>
                        <div className='profile-section-content d-flex flex-column'>
                            <ProfileSection icon='profile' name='Mon compte' info='Effectuer des modifications' route='my-infos'></ProfileSection>
                            { auth.canteenId !== null &&
                                <ProfileSection icon='house' name='Ma cantine' info='Changer ou ajouter une cantine' route='my-canteens'></ProfileSection>
                            }
                            { auth.childId !== null &&
                                <ProfileSection icon='house' name='Ma famille' info='Paramétrer et partager votre compte famille' route='my-family'></ProfileSection>
                            }
                            <ProfileSection icon='allergen' name='Mes allergies' info='Modifier mes allergies' route='my-constraint'></ProfileSection>
                            <ProfileSection icon='constraint' name='Mon régime alimentaire' info='Modifier mon régime alimentaire' route='my-dietetary'></ProfileSection>
                            {/*<ProfileSection icon='favorite' name='Mes plats favoris' info='Modifier mes plats favoris' route='my-favorite-meals'></ProfileSection>*/}
                            {/*<ProfileSection icon='profile' name='Invités' info='Ajouter des invités à votre reservation' route='my-invitation'></ProfileSection>*/}
                            {/* <ProfileSection icon='money' name='Mes paiements' info='Moyens de paiements et factures' route='my-payments'></ProfileSection> */}
                            <ProfileSection icon='shield' name='Changer mon mot de passe' info='Définir un nouveau mot de passe' route='my-password'></ProfileSection>
                            {/*<ProfileSection icon='history' name='Historique' info='Consulter l’historique des commandes' route='my-history'></ProfileSection>*/}
                            <ProfileSection icon='history' name='Comment ça fonctionne ?' info='Tutoriel' route='tutorial'></ProfileSection>
                        </div>

                        <div className='profile-section-title'>Autre</div>
                        <div className='profile-section-content d-flex flex-column'>
                            <ProfileSection icon='info2' name='Aide et support' route='help'></ProfileSection>
                            <ProfileSection icon='lock' name='Politique de confidentialité' route='confidential'></ProfileSection>
                            <ProfileSection icon='list' name='CGVU' route='cgvu'></ProfileSection>
                            <ProfileSection icon='logout' name='Déconnexion' route='logout'></ProfileSection>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile;
