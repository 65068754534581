import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';

const CallBackPage = () => {
    // Global
    const navigate = useNavigate();
    const [, setCookie] = useCookies();
    const { auth, setAuth, setIsLoading, setFlash, setShowFlash } = useAuth();
    const { type } = useParams();
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');

    // Check User / Token
    const getToken = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        try {
            const response = await axios.get(`/users/token/social${window.location.search}&type=${type}`);

            // Data
            let token = response.data.token;
            let refreshToken = response.data.refresh_token;
            let jwtDecode = jwt_decode(token);
            let username = jwtDecode.username;
            let lastName = jwtDecode.last_name;
            let firstName = jwtDecode.first_name;
            let roles = jwtDecode.roles;
            let jabuPoint = jwtDecode.jabu_point;
            let canteenId = jwtDecode.canteen_id;
            let childId = jwtDecode.user_child_id;
            let team = jwtDecode.team;
            //let lastLoggedOn = jwtDecode.last_logged_on;

            if (roles.includes('ROLE_GUEST')) {
                if (!jwtDecode.is_activate) {
                    navigate('/send-activate');
                } else {
                    setAuth({ username, lastName, firstName, roles, token, refreshToken, jabuPoint, canteenId, childId, team });
                    setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { username, lastName, firstName, roles, token, refreshToken, jabuPoint, canteenId, childId }, { path: '/' });

                    if (childId !== null || canteenId !== null) {
                        const response = await axios.get('/guests/canteen', {
                            params: {
                                childId: childId,
                            },
                            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
                            withCredentials: true
                        });
                        setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, {
                            id: response.data.canteen.id,
                            name: response.data.canteen.name
                        }, {path: '/'})
                    }

                    if (childId !== null) {
                        const response = await axios.get('/guests/child', {headers:{'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token}, withCredentials: true});
                        setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {id: response.data.child.id, name: response.data.child.firstName, point: response.data.child.jabuPoint}, { path: '/' })
                    }

                    /* await axios.put('/guests/last_logged', {
                        userId: jwtDecode.user_id,
                    }, { headers: {Authorization: `Bearer ${token}`} });

                    if( null == lastLoggedOn ){
                        setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, true, { path: '/' });
                        navigate('/tutorial');
                    }else{
                        setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, false, { path: '/' });
                        navigate('/');
                    }*/

                    if (canteenId === null && childId === null) {
                        navigate('/register-model');
                    } else {
                        window.location = '/';
                    }
                }
            } else {
                navigate('/unauthorized');
            }
        } catch (err) {
            setFlash({ message: 'Email ou Mot de passe incorrect' });
            setShowFlash(true);
            navigate('/login');
        }

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        // Redirect if connected
        if (auth?.username) {
            navigate('/');
        } else {
            getToken()
        }
    }, [])
};

export default CallBackPage;
