import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

const ConfirmFamily = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');

    // Global
    const navigate = useNavigate();
    const location = useLocation();
    const familyCode = location?.state?.codeFamily ?? '';
    const { setFlash, setShowFlash } = useAuth();

    const next = async () => {
        navigate('/my-constraint');
    }

    const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement('textarea'); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
    const copyToClipboard = (code) => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(code);
        } else {
            unsecuredCopyToClipboard(code);
        }

        setFlash({ message: 'Code Copié', type: 'success' });
        setShowFlash(true);
    };

    return (
        <>
            <div className='profil-page d-flex min-vh-100 justify-content-center'>
                <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                    <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                        <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Mon profil</div>
                    </div>

                    <div className='d-flex flex-column profile-block flex-grow-1 j'>
                        <div className='user-title d-flex justify-content-center'>
                            Le code famille a été créé avec succès
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div className='user-canteen'>
                                <p className='d-flex align-items-center justify-content-between'>
                                    <span>{familyCode}</span>
                                    <span><i role='presentation' onClick={() => copyToClipboard(familyCode)} className='ms-2 unsolds-card-infos fa-solid fa-copy'></i></span>
                                </p>
                            </div>
                            <button className='user-submit' type="button" onClick={() => next()}>Suivant</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfirmFamily;
