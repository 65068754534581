import React, { useState, useEffect } from 'react';
// import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';


const ProfileHeader = ({ bg}) => {
    // Provider
    const { auth } = useAuth();
    const [cookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_child`]);
    const [cookies] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_user`]);
    const userFirstName = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['name'] ?? cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['firstName'] ?? ' '
    const [avatarImage, setAvatarImage] = useState();
    const axiosPrivate = useAxiosPrivate();

    const getUser = async () => {
        const detailsResult = await axiosPrivate.get(`/guests/details?childId=${auth.childId}`, {
            withCredentials: true
        })
        let responseData = detailsResult.data;
        setAvatarImage(responseData.avatar);
    };

    useEffect(() => {
        getUser();
    }, []);

    let userPoints;
    if ('undefined' !== cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]){
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['point'] ?? 0
    } else {
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint'] ?? 0
    }

    return ( cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`] &&
        <>
            <div className={`${bg} d-flex flex-column w-100`}>
                <div className='d-flex p-1'>
                    <Link to='/my-profile' className='col-6 d-flex align-items-center'>
                        <div className='avatar-name-block'>
                            <span className='avatar-name'><img style={{width: '40px'}} src={`images/${avatarImage}`}  alt='avatar'></img></span>
                        </div>

                        <div className='d-flex' style={{'marginLeft': '20px'}}>
                            {userFirstName}
                        </div>
                    </Link>

                    <div className='header-qr-code col-6 d-flex justify-content-end align-items-center'>
                        <Link to='/challenge'>
                            <div className='header-points' style={{backgroundColor: 'transparent', color: 'black'}}>
                                <img style={{width: '40px'}} src='/images/points-icon.png' alt='points'></img>
                                <span className='avatar-name'>{userPoints}</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileHeader
