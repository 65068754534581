import React, { useEffect } from 'react';
import LoginTemplate from '../../components/Login/LoginTemplate';
import ForgotPassword from '../../components/Login/ForgotPassword';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');

    // Global
    const navigate = useNavigate();
    const { auth } = useAuth();

    useEffect(() => {
        // Redirect if connected
        if (auth?.username) {
            navigate('/');
        }
    }, [])

    return (
        <div className="forgot-password login-page">
            <LoginTemplate child={<ForgotPassword />}/>
        </div>
    );
};

export default ForgotPasswordPage;
