import React from 'react';
import TextField from '@mui/material/TextField';

const InputField = ({
    label,
    type = 'text',
    className = null,
    variant = 'outlined',
    size = 'small',
    margin = 'none',
    error = null,
    helper = null,
    inputRef = null,
    inputProps = null,
    forceShrink = null,
    color = '#FFF'
}) => {

    const labelProps = {
        sx: {
            color: color,
        },
    };
    // Force Label to shrink if setValue Form
    if (forceShrink) {
        labelProps.shrink = true;
    }

    return (
        <TextField
            label={label}
            type={type}
            className={className}
            variant={variant}
            size={size}
            margin={margin}
            sx={{
                'label': {fontFamily: 'Montserrat'},
                'label.Mui-focused': { color: color },
                '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: color, color: color },
                '.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { borderColor: color },
                '.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline': { borderColor: color },
                '.MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline': { borderColor: color },
                '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: color, color: color },
                '.MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline': { borderColor: color },
                '.MuiOutlinedInput-root.Mui-focused:active .MuiOutlinedInput-notchedOutline': { borderColor: color },
                '.MuiOutlinedInput-root.Mui-focused:focus .MuiOutlinedInput-notchedOutline': { borderColor: color },
                'label.Mui-focused.Mui-error': { color: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-error:active .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-error:focus .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-focused.Mui-error:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-focused.Mui-error:active .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
                '.MuiOutlinedInput-root.Mui-focused.Mui-error:focus .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
            }}
            InputLabelProps={labelProps}
            inputProps={{
                sx: {
                    color: color,
                },
            }}
            error={error}
            helperText={helper}
            inputRef={inputRef}
            {...inputProps}
        />
    );
};

export default InputField;
