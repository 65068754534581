import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import InputField from '../../components/InputField';
import { useCookies } from 'react-cookie';

const UserInfos = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');
    // Global
    const axiosPrivate = useAxiosPrivate();
    const [, setCookie] = useCookies();
    const navigate = useNavigate();
    const [displayUserInfos, setDisplayUserInfos] = useState(false);
    const { reset, register, handleSubmit, formState: { errors } } = useForm();
    const { auth, setAuth, setFlash, setShowFlash, setIsLoading, setModal, setShowModal } = useAuth();

    // Form
    const { refLastName, ...inputPropsLastName } = register('lastname', {
        required: 'Nom obligatoire',
        maxLength: {
            value: 50,
            message: 'Maximum 50 caractères',
        },
    });
    const { refFirstName, ...inputPropsFirstName } = register('firstname', {
        required: 'Prénom obligatoire',
        maxLength: {
            value: 50,
            message: 'Maximum 50 caractères',
        },
    });
    const { refUsername, ...inputPropsUsername } = register('email', {
        required: 'Adresse email obligatoire',
        maxLength: {
            value: 50,
            message: 'Maximum 50 caractères',
        },
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Mauvais format',
        }
    });
    const { refPhone, ...inputPropsPhone } = register('phone', {
        maxLength: {
            value: 20,
            message: 'Maximum 20 caractères',
        },
    });
    const { refAddress, ...inputPropsAddress } = register('address', {
        required: 'Adresse obligatoire',
        maxLength: {
            value: 255,
            message: 'Maximum 255 caractères',
        },
    });
    const { refAdditionalAddress, ...inputPropsAdditionalAddress } = register('additionalAddress', {
        maxLength: {
            value: 255,
            message: 'Maximum 255 caractères',
        },
    });
    const { refZipCode, ...inputPropsZipCode } = register('zipCode', {
        required: 'Code Postal obligatoire',
        maxLength: {
            value: 10,
            message: 'Maximum 10 caractères',
        },
    });
    const { refCity, ...inputPropsCity } = register('city', {
        required: 'Ville obligatoire',
        maxLength: {
            value: 50,
            message: 'Maximum 50 caractères',
        },
    });

    const getUser = async () => {
        setIsLoading(true);
        const result = await axiosPrivate.get('/guests', { withCredentials: true })
        let data = result.data;
        reset({
            //gender: data.gender,
            firstname: data.first_name,
            lastname: data.last_name,
            email: data.email,
            phone: data.phone,
            address: data.address,
            additionalAddress: data.additional_address,
            zipCode: data.zip,
            city: data.city,
        })
        setDisplayUserInfos(true);
        setIsLoading(false);
    };

    // Call Submit Register Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axiosPrivate.put('/guests', {
                gender: null,
                first_name: data.firstname,
                last_name: data.lastname,
                email: data.email,
                phone: data.phone,
                address: data.address,
                additional_address: data.additionalAddress,
                zip: data.zipCode,
                city: data.city,
            }, { withCredentials: true })
            setAuth({ ...auth, username: data.email, lastName: data.lastname, firstName: data.firstname});
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, username: data.email, lastName: data.lastname, firstName: data.firstname }, { path: '/' })
            setFlash({ message: 'Infos changé avec succès', type: 'success' });
            navigate('/my-profile');
        } catch (err) {
            let error;
            if (err?.response?.status === 409) {
                error = 'Email déjà utilisé';
            } else {
                error = 'Une Erreur est survenue';
            }
            setFlash({ message: error });
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    const confirm = async () => {
        setModal({
            title: <div style={{backgroundColor: '#0B50A4', color: '#fff'}} className='d-flex justify-content-center align-items-center flex-column py-2 mb-3'>Supprimer mon compte</div>,
            content: <div className='d-flex flex-column align-items-center p-4'>
                <div className='user-subtitle text-center mb-3'>Toutes vos données seront perdues après validation de la
                    suppression de votre compte. Elles ne pourront pas être récupérées. Appuyez sur valider si vous
                    souhaitez confirmer la suppression de votre compte
                </div>
                <div role='presentation' className='logout-confirm' onClick={() => deleteAccount()}>Valider</div>
                <div role='presentation' className='logout-cancel' onClick={() => setShowModal(false)}>Annuler</div>
            </div>
        })
        setShowModal(true)
    }

    const deleteAccount = async () => {
        setShowModal(false)

        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axiosPrivate.delete('/guests', {
                data: {},
                withCredentials: true
            })
            setFlash({ message: 'Votre compte a été supprimé', type: 'success' });
            navigate('/logout')
        } catch (err) {
            setFlash({ message: 'Une Erreur est survenue' });
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }


    useEffect(() => {
        getUser();
    }, []);

    return (
        <>
            {displayUserInfos &&
                <div className='profil-page d-flex min-vh-100 justify-content-center'>
                    <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                        <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                            <div className='d-flex profile-header-back'>
                                <Link to='/my-profile'>
                                    <span style={{borderRadius: '20px', padding: '10px' }}>
                                        <i style={{fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                    </span>
                                </Link>
                            </div>
                            <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Mon profil</div>
                        </div>

                        <div className='d-flex flex-column profile-block flex-grow-1'>
                            <div className='user-title d-flex justify-content-center'>
                                Mon compte
                            </div>

                            <form className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
                                <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1'>
                                    {/*<FormControl>
                                        <Controller
                                            rules={{ required: true }}
                                            control={control}
                                            name="gender"
                                            render={({ field }) => (
                                                <RadioGroup {...field} className="justify-content-center" defaultValue="1" row>
                                                    <FormControlLabel value="1" control={<Radio />} label="Homme" />
                                                    <FormControlLabel value="2" control={<Radio />} label="Femme" />
                                                </RadioGroup>
                                            )}
                                        />
                                    </FormControl>*/}
                                    <InputField color="#000" label="Nom *" className="user-input" error={!!errors.lastName} helper={errors?.lastName?.message} inputRef={refLastName} inputProps={inputPropsLastName}></InputField>
                                    <InputField color="#000" label="Prénom *" className="user-input" error={!!errors.firstName} helper={errors?.firstName?.message} inputRef={refFirstName} inputProps={inputPropsFirstName}></InputField>
                                    <InputField color="#000" label="Email *" className="user-input" error={!!errors.username} helper={errors?.username?.message} inputRef={refUsername} inputProps={inputPropsUsername}></InputField>
                                    <InputField color="#000" label="Téléphone" className="user-input" error={!!errors.phone} helper={errors?.phone?.message ? errors.phone.message : 'Optionnel'} inputRef={refPhone} inputProps={inputPropsPhone}></InputField>
                                    <InputField color="#000" label="Adresse *" className="user-input" error={!!errors.address} helper={errors?.address?.message} inputRef={refAddress} inputProps={inputPropsAddress}></InputField>
                                    <InputField color="#000" label="Adresse Complémentaire" className="user-input" error={!!errors.additionalAddress} helper={errors?.additionalAddress?.message ? errors.additionalAddress.message : 'Optionnel'} inputRef={refAdditionalAddress} inputProps={inputPropsAdditionalAddress}></InputField>
                                    <InputField color="#000" label="Code Postal *" className="user-input" error={!!errors.zipCode} helper={errors?.zipCode?.message} inputRef={refZipCode} inputProps={inputPropsZipCode}></InputField>
                                    <InputField color="#000" label="Ville *" className="user-input" error={!!errors.city} helper={errors?.city?.message} inputRef={refCity} inputProps={inputPropsCity}></InputField>
                                </div>

                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <input className='user-submit' type='submit' value='Valider'></input>
                                </div>
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <span role='presentation' onClick={() => confirm()} className='skip-constraint'>Supprimer mon compte</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default UserInfos;
