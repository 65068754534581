import React from 'react';
import { Link } from 'react-router-dom';

const UserPaymentPage = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');
    return (
        <div className="profil-page d-flex user-payment min-vh-100 justify-content-center">
            <div className="d-flex flex-column align-items-center flex-grow-1 flex-shrink-1">
                <div className="profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center">
                    <div className="d-flex profile-header-back">
                        <Link to='/my-profile'>
                            <span style={{borderRadius: '20px', padding: '10px' }}>
                                <i style={{fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                            </span>
                        </Link>
                    </div>
                    <div className="d-flex justify-content-center flex-grow-1 profile-header-text">Mon profil</div>
                </div>

                <div className="d-flex flex-column profile-block">
                    <div className="user-title d-flex justify-content-center">
                        Mes paiements
                    </div>

                    <div className="d-flex flex-column align-items-center">
                        <div id='unsold-infos'>
                            <p className='px-4 text-center'><img className='unsold-image' src='/images/unsolds.png' alt='Invendus'></img></p>
                            <p className='px-4 text-center'>En route pour l&apos;antigaspi ! 🌍🍴</p>
                            <p className='px-4 text-center'>Un peu de patience ! Bientôt vous pourrez dénicher les paniers anti-gaspi de Jabu et sauver les invendus de fin de service à prix doux.</p>
                            <p className='px-4 text-center'>Et le meilleur dans tout ça ? Vous pouvez gagner des points dès maintenant pour avoir accès à ces paniers dès leur lancement.</p>
                            <p className='px-4 text-center'>Chaque action compte : votez pour vos menus, indiquez votre présence et gagnez des points. Plus vous participez, plus vous économisez... et plus vous sauvez !</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPaymentPage;
