import React from 'react';
import { Link, useParams } from 'react-router-dom';

const ReservationConfirmation = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');
  
    let params = useParams()
    let today = new Date()
    let currentDate = params?.date ? params.date : today.toJSON().slice(0, 10)
    let aft = new Date(currentDate)
    let dayAfter = new Date(aft.setDate(aft.getDate() + 1))

    return (
        <div className='home min-vh-100 justify-content-center'>
            <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                <div className='d-flex flex-grow-1 flex-shrink-1 mt-2 min-vw-100'>
                    <div className='confirmation-info'>Bien reçu, bon appétit !</div>
                    <Link to={`/meal/${dayAfter.toJSON().slice(0, 10)}`}>
                        <div className='close-confirmation d-flex mr-1'>
                            <img src='/images/close-icon.png' alt='Fermer'></img>
                        </div>
                    </Link>
                </div>

                <div className='gain-points rounded-circle'>
                    <div className='points-claimed-info'>Vous venez de gagner</div>
                    <div className='points-claimed'>10pts</div>
                </div>

                <div className='points-info'>
                    <i>Vos points Jabu ont bien été crédité</i>
                </div>

                <div className='d-flex justify-content-center align-items-center mb-5'>
                    <Link to={'/my-points'}>
                        <button className='see-points rounded'>Consulter mon solde Jabu</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ReservationConfirmation;
