import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import InputField from '../InputField';

const LoginPassword = () => {
    // Global
    const navigate = useNavigate();
    const [, setCookie] = useCookies();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();

    // Form
    const { refUsername, ...inputPropsUsername } = register('username', {
        required: 'Champ Adresse email obligatoire',
    });
    const { refPassword, ...inputPropsPassword } = register('password', {
        required: 'Champ Mot de passe obligatoire',
    });

    // Call Login Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const response = await axios.post('/users/login', {
                username: data.username,
                password: data.password,
            });

            // Data
            let token = response.data.token;
            let refreshToken = response.data.refresh_token;
            let jwtDecode = jwt_decode(token);
            let username = jwtDecode.username;
            let lastName = jwtDecode.last_name;
            let firstName = jwtDecode.first_name;
            let roles = jwtDecode.roles;
            let jabuPoint = jwtDecode.jabu_point;
            let canteenId = jwtDecode.canteen_id;
            let childId = jwtDecode.user_child_id;
            //let lastLoggedOn = jwtDecode.last_logged_on;

            if (roles.includes('ROLE_GUEST')) {
                if (!jwtDecode.is_activate) {
                    navigate('/send-activate');
                } else {
                    setAuth({ username, lastName, firstName, roles, token, refreshToken, jabuPoint, canteenId, childId });
                    setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { username, lastName, firstName, roles, token, refreshToken, jabuPoint, canteenId, childId }, { path: '/' });

                    if (childId !== null || canteenId !== null) {
                        const response = await axios.get('/guests/canteen', {
                            params: {
                                childId: childId,
                            },
                            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
                            withCredentials: true
                        });
                        setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, {
                            id: response.data.canteen.id,
                            name: response.data.canteen.name
                        }, {path: '/'})
                    }

                    if (childId !== null) {
                        const response = await axios.get('/guests/child', {headers:{'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token}, withCredentials: true});
                        setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {id: response.data.child.id, name: response.data.child.firstName, point: response.data.child.jabuPoint}, { path: '/' })
                    }

                    /* await axios.put('/guests/last_logged', {
                        userId: jwtDecode.user_id,
                    }, { headers: {Authorization: `Bearer ${token}`} });

                    if( null == lastLoggedOn ){
                        setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, true, { path: '/' });
                        navigate('/tutorial');
                    }else{
                        setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, false, { path: '/' });
                        navigate('/');
                    }*/

                    if (canteenId === null && childId === null) {
                        navigate('/register-model');
                    } else {
                        window.location = '/';
                    }
                }
            } else {
                navigate('/unauthorized');
            }
        } catch (err) {
            setFlash({ message: 'Email ou Mot de passe incorrect' });
            setShowFlash(true);
        }

        // Loader
        setIsLoading(false);
    }

    return (
        <>
            <div className="login-form">
                <span className="login-subtext">Se connecter</span>
                <form className="d-flex flex-column" onSubmit={ handleSubmit(submit) } >
                    <InputField color="#94928B" label="Email" className="login-field" error={!!errors.username} helper={errors?.username?.message} inputRef={refUsername} inputProps={inputPropsUsername}></InputField>
                    
                    <InputField color="#94928B" label="Mot de Passe" type="password" className="login-field" error={!!errors.password} helper={errors?.password?.message} inputRef={refPassword} inputProps={inputPropsPassword}></InputField>
                    <span className="login-forgot-pass">
                        <Link to="/forgot-password">Mot de passe oublié ?</Link>
                    </span>

                    <button className="login-submit" type="submit">Se connecter</button>
                </form>

                <div className="login-subtitle">
                    Tu n&apos;as pas de compte ?&nbsp;<Link to="/register" className="text-decoration-underline">S&apos;inscrire</Link>
                </div>
            </div>
        </>
    );
};

export default LoginPassword;
