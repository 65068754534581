import React, { useEffect } from 'react';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const UnsoldsCart = () => {
    const { auth } = useAuth();
    const completeDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    const completeMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    const [cookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]);
    const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id']
    const canteenName = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['name']
    const axiosPrivate = useAxiosPrivate();

    let today = new Date()
    let currentDate = today.toJSON().slice(0, 10)
    let currentDay = new Date(currentDate)

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');

    const getCanteenInfos = async () => {
        const result = await axiosPrivate.get(`/guests/canteen?canteenId=${canteenId}`, {
            withCredentials: true,
            params: {
                childId: auth.childId,
            },
        })
        return result.data.canteen
    }

    useEffect(() => {
        getCanteenInfos()
    }, [])

    let cartInfos = [
        {quantity: 1, name: 'panier volaille', price: 3.3},
        {quantity: 2, name: 'panier viande', price: 6.6},
    ]

    let unsoldsInfos = {open: '14:00', close: '15:30'}

    let cartTotal = 0
    let cartLines = cartInfos.map((cartLine, key) => {
        cartTotal += cartLine.price
        return <div key={key} className='cart-line'>
            <div className='cart-line-text'>x{cartLine.quantity} {cartLine.name}</div>
            <div className='cart-line-amount'>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(cartLine.price)}</div>
        </div>
    });

    return (
        <div className='home-page unsolds d-flex min-vh-100 justify-content-center'>
            <div className='meal-header'>
                <ProfileHeader design='new' bg='calendar_reservation' canteenName={canteenName} page='unsolds'></ProfileHeader>
            </div>

            <div role='presentation' id='unsold-cart-infos' style={{alignItems:'initial'}}>
                <div className='cart-summary'>
                    <div className='cart-return'>
                        <div>
                            <Link to='/unsolds'>
                                <i className='unsolds-cart-button fa-solid fa-circle-chevron-left'></i>
                            </Link>
                        </div>
                        <div className='day'>
                            <div className='meal-complete-day d-flex justify-content-center mt-0'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                        </div>
                    </div>

                    <div className='cart-content'>
                        {cartLines}
                    </div>

                    <div className='cart-retrieve'>
                        <i className='fa-regular fa-clock'></i><div>A récupérer : {unsoldsInfos.open} - {unsoldsInfos.close}</div>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <div className='cart-total'>
                            Total : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(cartTotal) }
                        </div>
                    </div>
                </div>

                <div className='cart-pay-infos'>
                    <div className='unsolds-new-text'>Choisir un moyen de paiement</div>

                    <div className='apple-pay'>
                        <div className='apple-pay-text'> <i className='fa-brands fa-apple'></i> Pay</div>
                    </div>

                    <div className='credit-card'>
                        <div className='credit-card-text'>Carte bancaire</div>
                    </div>

                    <div className='unsolds-new-text-small text-center'>
                        En réservant ce panier, vous acceptez les <Link to='/cgvu'>Conditions Générales d’Utilisation</Link> de Jabu
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnsoldsCart;
